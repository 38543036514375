import './App.css';
import Home from './Component/Home';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Skill from './Component/Skill/Skill';
import Intro from './Component/Intro/Intro';
import Project from './Component/Project/Project'
import Contact from './Component/Contact/Contact'
import ModalDetail from './Component/ModalDetail/ModalDetail';
import About from './Component/About/About';
import { useSelector } from 'react-redux'
import Certificate from './Component/Certificate/Certificate';
function App() {
  const { themeLight } = useSelector(state => state.ThemeReducer)

  if (themeLight) 
    return (
      <div className='animate__animated animate__fadeIn' style={{ animationDelay: '1s' }} data-theme='valentine'>
        <ModalDetail></ModalDetail>
        <BrowserRouter>
          <Routes>
            <Route path='/' element={<Home></Home>}>
              <Route index element={<Intro></Intro>}></Route>
              <Route path='skill' element={<Skill></Skill>}></Route>
              <Route path='project' element={<Project></Project>}></Route>
              <Route path='contact' element={<Contact></Contact>}></Route>
              <Route path='about' element={<About></About>}></Route>
              <Route path='degree' element={<Certificate></Certificate>}></Route>

            </Route>
          </Routes>
        </BrowserRouter>
      </div>
    )
  return (

    <div className='animate__animated animate__fadeIn' style={{ animationDelay: '1s' }} >
      <ModalDetail></ModalDetail>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home></Home>}>
            <Route index element={<Intro></Intro>}></Route>
            <Route path='skill' element={<Skill></Skill>}></Route>
            <Route path='project' element={<Project></Project>}></Route>
            <Route path='contact' element={<Contact></Contact>}></Route>
            <Route path='about' element={<About></About>}></Route>
            <Route path='degree' element={<Certificate></Certificate>}></Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </div>)


}

export default App;
