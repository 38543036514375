import { Light } from "../Action/ThemeAction"

const initialState = {
    themeLight: false
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
    switch (action.type) {

        case Light:
            return { ...state, themeLight: !state.themeLight }

        default:
            return state
    }
}
