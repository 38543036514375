import React from 'react'
import { useSelector } from 'react-redux'

export default function ModalDetail() {
  const { details } = useSelector(state => state.ProjectInfor)
  const showImg = (type, img) => {
    if (type) {
      return <img src={img} alt='' />
    }
  }
  return (
    <div>
      <input type="checkbox" id="my-modal-4" className="modal-toggle" />
      <label htmlFor="my-modal-4" className="modal cursor-pointer">
        <label className="modal-box relative" htmlFor>
          <h3 className="text-lg font-bold">Intruction</h3>
          <p className="py-4">{details}</p>
          {showImg(details.type, details.img)}
        </label>
      </label>
    </div>

  )
}
