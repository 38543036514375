import React from "react"

export const tagWritting = (tag) => {
    return <span style={{
        fontFamily: "Coding_Style",
        fontSize: "25px",
        color: "aquamarine",
    }}>
        {tag}
    </span>
}
