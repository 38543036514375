import React from 'react'
import { Link } from 'react-router-dom'
import styleIntro from './Intro.module.css'
import 'animate.css'
import { tagWritting } from '../Tag/Tag'
import Dao_CV from '../../resource/cv/Tuan_Dao_CV.pdf'

export default function Intro() {
    return (
        <div className='flex align-middle justify-center'>
           
        <div className=' p-10 animate__animated animate__fadeInRightBig h-screen flex justify-center align-middle'    >
            <div className='grid grid-cols-2 ' id="Intro" >

                <div className={styleIntro["i-left"]} >
                    {tagWritting('<body>')}

                    <div className='pl-10'>

                        {tagWritting(`<p>`)}
                    </div>
                    <div className={styleIntro["i-name"]}>
                        {/* yahan change hy darkmode ka */}
                        <span style={{}} >Hey ! My Name is </span>
                        <span>Dao Nhat Tuan</span>
                        <span className='text-xl'>Software Developer with high level in web design and development, producing  quality projects</span>
                    </div>
                    <div className='flex gap-5'>

                        <button className="btn btn-outline btn-error w-24">
                            <Link to="contact" smooth={true} spy={true}>
                                Hire Me
                            </Link>
                        </button>
                        <button className="btn btn-error hover:btn-secondary w-24">
                                <a href='https://github.com/TuanDao-0110' target="_blank" rel="noopener noreferrer">
                                <img src={require('./github.png')} alt='' className='w-8 '></img>
                            </a>
                        </button>
                        <button className="btn  btn-warning hover:btn-secondary w-24">
                            <a href={Dao_CV} target="_blank" rel="noopener noreferrer">
                                <img src={require('./Cv.png')} alt='' className='w-8 '></img>
                            </a>
                        </button>
                    </div>
                    {/* social icons */}

                    <div className='pl-10'>

                        {tagWritting(`<p/>`)}
                    </div>
                    {tagWritting('<body/>')}

                </div>
                <div className={styleIntro["i-right"]}>
                    <div className='flex justify-end pt-10'>

                        <img src={require('./boy.PNG')} alt="" className=" m-auto md:visible invisible" />
                    </div>
                </div>
            </div>

        </div> 
        </div>


    )
}
