/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { tagWritting } from '../Tag/Tag'
import Dao_CV from '../../resource/cv/Tuan_Dao_CV.pdf'
import styled from './About.module.css'

export default function About() {
    return (
        <div className='p-10  animate__animated animate__fadeInRightBig' >
            <div className='grid xl:grid-cols-2 grid-cols-1' >
                <div className=' flex flex-col  h-full justify-between  pt-5 '>
                    {tagWritting('<body>')}
                    <div className='pl-10'>

                        {tagWritting(`<p>`)}
                    </div>

                    <div className='flex flex-col h-3/5 lg:gap-10  gap-3'>
                        <h3 class={styled['header']}>Hello</h3>

                        <div className='text-gray-400 '>

                            <p className='text-left text-xl'>
                                I am a web developer based in Helsinki, Finland with a background in engineering.
                                I am passionate about coding and solving problems, and I am always eager to take on interesting and ambitious projects.
                                In my free time, I enjoy playing football and participating in outdoor activities.
                                I am well-organized and pay close attention to details, and I am excited to work with positive and motivated individuals to create something special.
                            </p>
                            <p className='mt-5 text-xl'>
                                Let's work together to make it happen!
                            </p>

                        </div>
                    </div>

                    <div className='pl-10'>

                        {tagWritting(`<p/>`)}
                    </div>
                    {tagWritting('<body/>')}
                </div>
                <div className='lg:scale-100 scale-75'>
                    <h2 className='text-3xl text-center'> Education</h2>
                    <div className='flex  flex-col items-center pt-32 align-middle'>

                        <ol className="border-l scale-150">
                            <li>
                                <div className="flex flex-start items-center ">
                                    <div className="bg-gray-300 w-2 h-2 rounded-full -ml-1 mr-3" />
                                    <a href='https://en.bc.fi/qualifications/full-stack-web-developer-program/' target={'_blank'} className="text-gray-500 text-md flex items-stretch" > <span className='self-center'>8.2022-present</span><img src={require('./img/business college.png')} className='w-16 h-10 ml-5' alt='' /></a>
                                </div>
                                <div className="mt-0.5 ml-4 mb-6">
                                    <h4 className="text-red-300 font-thin text-xl mb-1.5">Software Developer Program </h4>
                                </div>
                            </li>
                            <li>
                                <div className="flex flex-start items-center ">
                                    <div className="bg-gray-300 w-2 h-2 rounded-full -ml-1 mr-3" />
                                    <a target={'_blank'} href={'https://www.gptgroup.net/'} className="text-gray-500 text-md flex items-stretch"> <span className='self-center'>7.2022-9.2022</span><img src={require('./img/GPT.png')} className='w-16 h-10 ml-5' alt='' /></a>
                                </div>
                                <div className="mt-0.5 ml-4 mb-6">
                                    <h4 className="text-red-300 font-thin text-xl mb-1.5">Front End Developer at GPT Group</h4>
                                </div>
                            </li>
                            <li>
                                <div className="flex flex-start items-center ">
                                    <div className="bg-gray-300 w-2 h-2 rounded-full -ml-1 mr-3" />
                                    <a target={'_blank'} href={'https://cyberlearn.vn/'} className="text-gray-500 text-md flex items-stretch"> <span className='self-center'>7.2021-present</span><img src={require('./img/CyberLearn_Logo.png')} className='w-16 h-6 ml-5' alt='' /></a>
                                </div>
                                <div className="mt-0.5 ml-4 mb-6">
                                    <h4 className="text-red-300 font-thin text-xl mb-1.5">Cyber Learn Front-End-Training</h4>
                                </div>
                            </li>
                            <li>
                                <div className="flex flex-start items-center pt-5">
                                    <div className="bg-gray-300 w-2 h-2 rounded-full -ml-1 mr-3" />
                                    <a target={'_blank'} href={'https://www.metropolia.fi/'} className="text-gray-500 text-md flex items-stretch"> <span className='self-center'>2013-2019</span><img src={require('./img/Metropolia_Logo.jpg')} className='w-16 h-6 ml-5' alt='' /></a>
                                </div>
                                <div className="mt-0.5 ml-4 mb-6">
                                    <h4 className="text-red-300 font-thin text-xl mb-1.5">Metropolia University Applied Science</h4>
                                    <p className="text-gray-500 mb-3">Civil Engineer Degree</p>

                                </div>
                            </li>
                           
                        </ol>
                    </div>
                    <div className='pt-32 text-center'>
                        <a href={Dao_CV} download >
                            <button className="btn ">Download CV</button>
                        </a>
                    </div>
                </div>


            </div>




        </div>
    )
}
