import React from 'react'
import {
    faCss3,
    faGitAlt,
    faHtml5,
    faJsSquare,
    faReact,
    faJava,
    
} from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styleSkill from './Skill.module.css'
import { tagWritting } from '../Tag/Tag'
export default function Skill() {
    return (
        <div className=' animate__animated animate__fadeInRightBig ' >
            <div div className=' grid grid-cols-2  p-16 content-center' >
                <div className=' flex flex-col h-3/4 justify-between  '>

                    {tagWritting('<body>')}

                    <div className=' flex flex-col h-2/3 gap-16 pt-5 '>

                        <h3 className={styleSkill['skill_header']}> Skill</h3>
                        <p className='text-xl'>
                            Happy to use all those skills for job.
                            <span className='text-rose-200 mx-2'>
                                React
                            </span>
                            is my working Framework.

                            I am thirsty for new knowledge and ready to explore the technology world.
                        </p>

                        <button className="btn btn-outline btn-error w-fit"><FontAwesomeIcon icon="fa-solid fa-terminal" color='blue' />Hire me</button>
                    </div>
                    {tagWritting('<body/>')}
                </div>
                <div className='lg:visible  invisible' style={{
                    height: '100vh',

                }}>
                    <div className={styleSkill["stage-cube-cont"]} >
                        <div className={styleSkill["cubespinner"]}>
                            <div className={styleSkill.face1}>
                                <FontAwesomeIcon icon={faJava} size='2x' color="#4B8BBE" />
                            </div>
                            <div className={styleSkill.face2}>
                                <FontAwesomeIcon icon={faHtml5} size='2x' color="#F06529" />
                            </div>
                            <div className={styleSkill.face3}>
                                <FontAwesomeIcon icon={faCss3} size='2x' color="#28A4D9" />
                            </div>
                            <div className={styleSkill.face4}>
                                <FontAwesomeIcon icon={faReact} size='2x' color="#5ED4F4" />
                            </div>
                            <div className={styleSkill.face5}>
                                {/* <FontAwesomeIcon icon={faJsSquare} size='2x' color="#EFD81D" /> */}
                                TS
                            </div>
                            <div className={styleSkill.face6}>
                                <FontAwesomeIcon icon={faGitAlt} size='2x' color="#EC4D28" />
                            </div>
                           
                        </div>
                    </div>
                </div>

            </div>
        </div>

    )
}
