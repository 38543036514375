import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DETAILS } from '../../Redux/Action/DetailsAction'
import { AWS, CERTIFICATE, DEGREE } from '../../Redux/Reducer/ProjectInfor'


export default function Certificate() {
    const { certificates } = useSelector(state => state.ProjectInfor)
    const dispatch = useDispatch()
    const renderCard = (item, index) => {
        return <div className="card card-compact w-96  bg-base-200 my-10  shadow-xl " key={index} style={{
            height: '500px'
        }}>
            <figure><img className='
      h-72' src={item.img} alt="Shoes" /></figure>
            <div className="card-body">
                <h2 className="card-title">{item.title}</h2>
                <p className='text-rose-400'>{item.discription}</p>
                <p>{item.noteLogin}</p>
                <p className='text-red-500'>{item.note}</p>
                <div className="card-actions justify-center">
                    {
                        item.type !== DEGREE ?
                            <button
                                className="btn btn-primary" onClick={() => {
                                    dispatch({
                                        type: DETAILS,
                                        details: item.details
                                    })
                                }}>
                                <label for="my-modal-4" className="">
                                    Details
                                </label>
                            </button>
                            : <a href={item.link}
                                className="btn btn-primary"
                                target="_blank" rel="noopener noreferrer"
                            >
                                Details
                            </a>
                    }
                </div>
            </div>
        </div>

    }
    const renderDataMovie = () => {
        return certificates.map((item, index) => {
            if (item.type === CERTIFICATE && certificate)
                return renderCard(item, index)
            if (item.type === DEGREE && degree)
                return renderCard(item, index)
            if (item.type === AWS && aws)
                return renderCard(item, index)
        })
    }

    const [aws, setaws] = useState(true)
    const [degree, setDegree] = useState(true)
    const [certificate, setCertificate] = useState(true)
    return (

        <div className='animate__animated animate__fadeInRightBig' >
            <div className='flex  w-4/6 justify-around m-auto'>
                <div className="form-control w-fit"
                    onChange={() => {
                        setaws(!aws)
                    }}
                >
                    <label className="cursor-pointer label"

                    >
                        <span className="label-text px-2">AWS </span>
                        <input type="checkbox" checked={aws}

                            className="checkbox" />
                    </label>
                </div>
                <div className="form-control w-fit"
                    onChange={() => {
                        setDegree(!degree)
                    }}
                >
                    <label className="cursor-pointer label college">
                        <span className="label-text px-2">College Degree</span>
                        <input type="checkbox" checked={degree}
                            className="checkbox checkbox-secondary" />
                    </label>
                </div>
                <div className="form-control w-fit"
                    onChange={() => {
                        setCertificate(!certificate)
                    }}
                >
                    <label className="cursor-pointer label uniofhel">
                        <span className="label-text px-2">Uni of Helsinki</span>
                        <input type="checkbox" checked={certificate}
                            className="checkbox checkbox-accent" />
                    </label>
                </div>
            </div>
            <div className='grid xl:grid-cols-3 lg:grid-cols-2 gap-5 grid-cols-1 justify-items-center '>
                {renderDataMovie()}
            </div>
        </div>
    )
}
