import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { DETAILS } from '../../Redux/Action/DetailsAction'
export default function Project() {

  const { dataMovie } = useSelector(state => state.ProjectInfor)
  const dispatch = useDispatch()
  const renderDataMovie = () => {
    return dataMovie.map((item, index) => {
      return <div className="card card-compact w-96  bg-base-200 my-10  shadow-xl " key={index} style={{
        height: '500px'
      }}>
        <figure><img className='
      h-72' src={item.img} alt="Shoes" /></figure>
        <div className="card-body">
          <h2 className="card-title">{item.title}</h2>
          <p className='text-rose-400'>{item.discription}</p>
          <p>{item.noteLogin}</p>
          <p className='text-red-500'>{item.note}</p>
          <div className="card-actions justify-between">
            <button className="btn btn-primary"><a href={item.gitHubLink} target="_blank" rel="noopener noreferrer">
              Visit Github</a></button>

            <button className="btn btn-primary" onClick={() => {
              dispatch({
                type: DETAILS,
                details: item.details
              })
            }}><label for="my-modal-4" className="">Details</label></button>
            <button className="btn btn-primary">
              <a href={item.webLink} target="_blank" rel="noopener noreferrer">
                Visit    Page
              </a></button>

          </div>
        </div>
      </div>
    })
  }
  return (

    <div className='animate__animated animate__fadeInRightBig' >

      <div className='grid xl:grid-cols-3 lg:grid-cols-2 gap-5 grid-cols-1 justify-items-center '>
        {renderDataMovie()}
      </div>
    </div>
  )
}
