import { DEGREE_DETAILS, DETAILS } from '../Action/DetailsAction'
import awsCodeCommit from '../../resource/certificate/AWS Code Commit.png'
import devOps from '../../resource/certificate/DevOps On AWS.png'
import introAmazon from '../../resource/certificate/Introduction to Amazon Elastic Compute Cloud EC2.png'
import fullstack from '../../resource/certificate/certificate-fullstack.png'
import reactNative from '../../resource/certificate/certificate-reactnative.png'
import graphQl from '../../resource/certificate/certificate-graphql.png'
import typescript from '../../resource/certificate/certificate-typescript.png'
import cicd from '../../resource/certificate/certificate-cicd.png'
import studyCertificate from '../../resource/degrees/study_certificate.png'
import certificate from '../../resource/degrees/degree.png'
import linkStudyCertificate from '../../resource/degrees/study_certificate.pdf'
import linkCertificate from '../../resource/degrees/Tutkintotodistus_Dao_Tuan_22563_1695726167783_signed.pdf'
export const CERTIFICATE = 'certificate'
export const DEGREE = 'degree'
export const AWS = 'aws'
const initialState = {
    dataMovie: [{
        title: 'Movie Booking',
        discription: 'React, Rest API, TailWindCss',
        noteLogin: 'UserName : John@gmail.com Password: John@gmail.com',
        note: " please use Insercure content to log in/sign up",
        details: 'please Allow Insecure-content because I use http Domain , Project use REST API to login/sign up, Test account can you to book new Film. But also can access Admin Pages where you can edit/delete/add User/Film Details',
        gitHubLink: 'https://github.com/Tuandao1991/MovieBooking',
        webLink: 'https://moviebooking.pages.dev/',
        img: './ProjectImg/MovieBooking.gif'
    }, {
        title: 'Honda Commercial Website',
        discription: 'HTML,ES6,Jquery',
        noteLogin: '',
        note: "",
        details: 'Project is clone a commercial website and practice how to use Jquery',
        gitHubLink: 'https://github.com/Tuandao1991/Honda_Clone_Page',
        webLink: 'https://honda-clone-page.pages.dev/',
        img: './ProjectImg/HondaPages.gif'
    }, {
        title: 'Change Clothes',
        discription: 'React, Redux',
        noteLogin: '',
        note: " ",
        details: 'Project is creating testing system that help buyer easy to test their clothes before they decide to buy a product. This is very useful for small online business commercial website. ',
        gitHubLink: 'https://github.com/Tuandao1991/Change_Clothe',
        webLink: 'https://change-clothe.pages.dev/',
        img: './ProjectImg/ChangeClothes.gif'
    }, {
        title: 'User Manager System',
        discription: 'React, Rest API, Ant Design',
        noteLogin: 'UserName : long@gmai.com Password: 12345',
        note: " please use Insercure content to log in/sign up",
        details: 'Project is working like admin website that use Rest API to connect with backend data. Project can edit/add/delete user details. Project can apply for school, company to mamager their employes information',
        gitHubLink: 'https://github.com/Tuandao1991/Change_Clothe',
        webLink: 'https://usermanager.pages.dev/',
        img: './ProjectImg/UserManager.gif'
    }, {
        title: 'Movie Page UI',
        discription: 'HTML, CSS, Boostrap',
        noteLogin: '',
        note: " ",
        details: 'Project run on HTML CSS, Bootstrap and basic JS. Project is only use raw CSS vs HTML, it can apply for movie theather booking system ',
        gitHubLink: 'https://github.com/Tuandao1991/Movie_Page',
        webLink: 'https://movie-page.pages.dev/',
        img: './ProjectImg/MoviePageUi.gif'
    },

    ],
    details: '',
    certificates: [{
        type: AWS,
        title: 'AWS code commit',
        details: 'AWS CodeCommit is a version control service hosted by Amazon Web Services that you can use to privately store and manage assets(such as documents, source code, and binary files) in the cloud',
        img: awsCodeCommit
    },
    {
        type: AWS,
        title: 'AWS DevOps Certificate',
        details: 'Professional showcases individuals technical expertise in provisioning, operating, and managing distributed application systems on the AWS platform, giving them increased confidence and credibility with peers, stakeholders, and customers',
        img: devOps
    }, {
        type: AWS,
        title: 'Introduction to AWS',
        details: 'The AWS Certified Cloud Practitioner is an entry- level certification designed to validate a candidate s overall understanding of the AWS cloud.',
        img: introAmazon
    },
    {
        type: CERTIFICATE,
        title: 'Uni of Helsinki Full Stack Certificate ',
        details: '',
        img: fullstack
    },
    {
        type: CERTIFICATE,
        title: 'Uni of Helsinki React Native Certificate ',
        details: '',
        img: reactNative
    },
    {
        type: CERTIFICATE,
        title: 'Uni of Helsinki GraphQL Certificate ',
        details: '',
        img: graphQl
    },
    {
        type: CERTIFICATE,
        title: 'Uni of Helsinki Typescript Certificate ',
        details: '',
        img: typescript
    },
    {
        type: CERTIFICATE,
        title: 'Uni of Helsinki CICD Certificate ',
        details: '',
        img: cicd
    },
    {
        type: DEGREE,
        title: 'Study Certificate BHC',
        details: '',
        img: studyCertificate,
        link: linkStudyCertificate
    },
    {
        type: DEGREE,
        title: 'College Certificate BHC',
        details: '',
        img: certificate,
        link: linkCertificate
    }
    ],
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
    switch (action.type) {
        case DETAILS: return { ...state, details: action.details }
        case DEGREE_DETAILS: return { ...state, degreeDetails: action.degreeDetails, }
        default:
            return state
    }
}
