import React, { useRef, useState } from 'react'
import emailjs from '@emailjs/browser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Contact() {

  const form = useRef();
  const [done, setDone] = useState(false);
  const [warning, setWaring] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    const listArrayInputUser = document.getElementsByClassName("user");
    for (let i = 0; i < listArrayInputUser.length; i++) {
      if (listArrayInputUser[i].value === "") {
        setWaring(true);
        setDone(false)
        return;
      }

      if (i === listArrayInputUser.length - 1) {
        setWaring(false);
        emailjs.sendForm("service_ynztw7q", "template_ho8mwa5", form.current, "7-wFmY3HA9UtnJv4x").then(
          (result) => {
            setDone(true);
            setWaring(false)
            form.current.reset();
          },
          (error) => {
          }
        );
      }
    }
  };
  return (
    <div className='animate__animated animate__fadeInRightBig h-screen'  >
      <div className=" items-center pt-36 mx-auto " style={{ width: '500px', margin: 'auto' }}>
        <div className="block p-4 rounded-lg shadow-lg  ">
          <form ref={form} onSubmit={sendEmail}>
            <label>Please Leave Me Your Contact</label>
            <div className="form-group mb-6">
              <input type="text" name="user_name" className="form-control block user
  w-full
  px-3
  py-1.5
  text-base
  font-normal
  text-gray-700
  bg-white bg-clip-padding
  border border-solid border-gray-300
  rounded
  transition
  ease-in-out
  m-0
  focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"  placeholder="Name" />
            </div>
            <div className="form-group mb-6">
              <input type="email" name="user_email" className="form-control block user
  w-full
  px-3
  py-1.5
  text-base
  font-normal
  text-gray-700
  bg-white bg-clip-padding
  border border-solid border-gray-300
  rounded
  transition
  ease-in-out
  m-0
  focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"  placeholder="Email address" />
            </div>
            <div className="form-group mb-6">
              <textarea name="message" className=" user
  form-control
  block
  w-full
  px-3
  py-1.5
  text-base
  font-normal
  text-gray-700
  bg-white bg-clip-padding
  border border-solid border-gray-300
  rounded
  transition
  ease-in-out
  m-0
  focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"  rows={3} placeholder="Message" defaultValue={""} />
            </div>

            <button type="submit" className="w-full
px-6
py-2.5
bg-blue-600
text-white
font-medium
text-xs
leading-tight
uppercase
rounded
shadow-md
hover:bg-blue-700 hover:shadow-lg
focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0
active:bg-blue-800 active:shadow-lg
transition
duration-150
ease-in-out
flex justify-center gap-5
"> Send <img src={require('./send.png')} alt='' className='w-5'></img>
              
            </button>
          </form>

          {done ? <span className='text-green-400'>Thank For Contact Me</span> : ''}
          {warning ? <span className='text-red-400'>Please Provide Me All Information</span> : ''}
        </div>
      </div>


    </div>
  )
}
